import React from 'react';

// returns back a string if minimumSecondsToReveal is met, and a boolean of "shouldShow"
export const usePlaceHolderText = (text, minimumSecondsToReveal = 0, shouldHide = false) => {

    const [shouldShow, setShouldShow] = React.useState(false);

    React.useEffect(() => {
        if(!shouldHide && shouldShow) return setShouldShow(false);
        setTimeout(() => {
            setShouldShow(true);
        }, minimumSecondsToReveal * 1000);
    }, [shouldHide]);

    // log when shouldshow updates
    React.useEffect(() => {
        console.log("shouldShow", shouldShow)
    }, [shouldShow]);


    return shouldShow && !shouldHide ? text : null;
}