import logo from './logo.svg';
import './App.css';
import styled from 'styled-components';
import { useState, useEffect } from 'react';

import { getTokenInfo } from './utils/api';
import { usePlaceHolderText } from './_hooks/usePlaceholderText';

const Centered = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const DropdownInput = styled.select`
  min-width: 12ch;
  height: 50px;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;

  transform: translateY(1px);
`;

const InputsParent = styled.div`
  width: 100%;
  margin: 0 auto;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  align-items: center;
  color: #ccc;
  font-size: 2em;
  margin-bottom: 2ch;

  span {
    color: #fff;
    font-weight: bold;
  }

  // change the dropdown input style to be more inline with the text style
  select {
    font-size: 1em;
    min-width: unset;
    background: unset;
    border: unset;
    color: #fff;
    font-weight: bold;
    outline: 2px solid #00000000;

    &:hover,&:focus {
      outline: 2px solid #fff;
    }

    // change drop down boxes to have dark background
    option {
      background: #252635;
      color: #ccc;
      transition: all 0.2s ease-in-out;
      &:hover,&:focus, &:active {
        background: #252635;
        color: #fff;
        font-weight: bold;
      }
    }
  }
`;

const TextCompare = styled.div`
  font-size: 1.5em;
  margin: 0 10px;
  color: #ccc;

  // change how a's look
  a,span {
    color: #eee;
    text-decoration: none;
    font-weight: bold;
  }
  span {
    color: #eee;
  }
  a {
    &:hover {
      color: #fff;
      // underline
      text-decoration: underline;
    }
  }
`

const PriceWithOtherMarketcap = styled.div`
  font-size: 1.5em;
  margin: 0 10px;
  color: #ccc;

  span {
    font-weight: bold;
    color: #eee;
  }
`;

const DummySpacer = styled.div`

  // user can pass in fadeIn, if they do, then we will fade in the dummy spacer
  // otherwise, we will just show it
  opacity: ${props => props.fadeIn ? 0 : 1};
  animation: ${props => props.fadeIn ? 'fadeIn 0.2s ease-in-out forwards' : 'none'};

  color: #ccc;
  font-size: 1.5em;
  font-weight: bold;

  // if fadeIn is true, then we will fade in the dummy spacer
  // use animation to do this
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }



`;

const FadeInChildren = styled.div`
  & > * {
    opacity: ${props => props.fade ? 0 : 1};
    animation: ${props => props.fade ? 'fadeIn 0.2s ease-in-out forwards' : 'none'};
    &.fadeOut > * {
      opacity: ${props => props.fade ? 0 : 1};
      animation: ${props => props.fade ? 'fadeIn 0.2s ease-in-out forwards' : 'none'};
    }
  }


  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  `

const FadeOutChildren = styled.div`
  & > * {
    opacity: 1;
    animation: ${props => props.fade ? 'fadeOut 0.2s ease-in-out !important' : 'none'} ;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`

function App() {

  const [compareTo, setCompareTo] = useState('btc');
  const [compareToInfo, setCompareToInfo] = useState({});
  const [taoInfo, setTaoInfo] = useState({});
  const [taoPriceWithOtherMarketCap, setTaoPriceWithOtherMarketCap] = useState(0);
  const [multiplier, setMultiplier] = useState(0);
  const [LOAD_DATA, SET_LOAD_DATA] = useState(false);

  const [fadeOut, setFadeOut] = useState(false);

  const DATA_LOADED = taoInfo && compareToInfo && Object.keys(taoInfo).length > 0 && Object.keys(compareToInfo).length > 0;
  const LoadingText = usePlaceHolderText("fetching data, one moment...", 0.5, DATA_LOADED);

  useEffect(() => {
    setTimeout(() => {
      SET_LOAD_DATA(true);
    }, 2500)
  }, [])

  useEffect(() => {
    if(!LOAD_DATA) return;
    getTokenInfo('tao').then((data) => {
      console.log("tao token info", data);
      setTaoInfo(data);
    });
  }, [LOAD_DATA])

  useEffect(() => {
    if(!LOAD_DATA || fadeOut) return;
    getTokenInfo(compareTo).then((data) => {
      setCompareToInfo(data);
      setFadeOut(false);
    });
  }, [compareTo, LOAD_DATA, fadeOut])

  const URL_MAP = {
    'btc': 'https://coinmarketcap.com/currencies/bitcoin/',
    'eth': 'https://coinmarketcap.com/currencies/ethereum/',
    'doge': 'https://coinmarketcap.com/currencies/dogecoin/',
  }

  useEffect(() => {
    const { multiplier, taoPriceWithOtherMarketCap } = calculateMarketCapDifference(taoInfo, compareToInfo);
    console.log("xyz", taoInfo, compareToInfo)
    console.log("multiplier", multiplier)
    console.log("taoPriceWithOtherMarketCap", taoPriceWithOtherMarketCap)
    if (multiplier == 0) {
      return;
    }
    setMultiplier(multiplier);
    setTaoPriceWithOtherMarketCap(taoPriceWithOtherMarketCap);
    setFadeOut(false);
  }, [taoInfo, compareToInfo])

  useEffect(() => {
    if(!fadeOut) return;
    setTimeout(() => {
      setMultiplier(0);
      setTaoPriceWithOtherMarketCap(0);
      setCompareToInfo({});
      setFadeOut(false);
    }, 200)
  }, [fadeOut])

  return (
    <Centered className="App">
      {/* <h1 className="App-header">
        Welcome to Market Cap Of TAO!
      </h1> */}
      <FadeInChildren fade={true}>
      <InputsParent>
        <span>TAO</span> to
        <DropdownInput value={compareTo} onChange={
          (e) => { setFadeOut(true);
            setCompareTo(e.target.value);

          }}
          style={{
            width: `${compareTo.length + 2.75}ch`,
            marginLeft: "0.5ch"
          }}
        >
          <option value="btc">BTC</option>
          <option value="eth">ETH</option>
          <option value="doge">DOGE</option>
        </DropdownInput>
      </InputsParent>

      
      <FadeOutChildren fade={fadeOut} className={'fadeOut'}>
      {DATA_LOADED ? (
        <TextCompare style={{
          animationDelay: "0.2s"
        }}>
        <a href="https://coinmarketcap.com/currencies/bittensor/" target="_blank">TAO</a> is worth <span>${taoInfo.price}</span> with a market cap of <span>${shorthandNumber(taoInfo.marketCap)}</span><br/><a href={URL_MAP[compareTo]} target="_blank">{compareTo.toUpperCase()}</a> is worth <span>${formatNumber(compareToInfo.price)}</span> with a market cap of <span>${shorthandNumber(compareToInfo.marketCap)}</span><br/><br/>The difference in market cap is <span>${shorthandNumber(Math.abs(taoInfo.marketCap - compareToInfo.marketCap))}</span>
      </TextCompare>
      ) : /*<DummySpacer style={{height: "3.6em"}}></DummySpacer>*/ null}
      {LoadingText && !DATA_LOADED ? <DummySpacer style={{height: "3.6em"}} fadeIn={true}>{LoadingText}</DummySpacer> : null}
      
      {multiplier && multiplier !== "NaN" ? (
        <PriceWithOtherMarketcap style={{
          animationDelay: "0.4s"
        }}>
          <br/>
          If <span>TAO</span> had the market cap of <span>{compareTo.toUpperCase()}...</span><br/>One token would be worth <span>${taoPriceWithOtherMarketCap}</span> (<span style={{
            color: "#aaffcc"
          }}>x{formatNumber(multiplier)}</span>){multiplier >= 100 ? "! 😱😱😱" : multiplier >= 100 ? "! 😱" : multiplier >= 20 ? "!!!" : multiplier >= 10 ? "!!" : multiplier >= 2 ? "!" : ""}
        </PriceWithOtherMarketcap>
      ) : null}
      </FadeOutChildren>

      </FadeInChildren>
    </Centered>
  );

  // Take both info of TAO and the other token
  // calculate the difference in market cap (find the multiplier of TAO needed to match the other token's market cap)
  // return both the multiplier and the price of TAO with that mulitplier
  function calculateMarketCapDifference(taoInfo, compareToInfo) {
    console.log(taoInfo, compareToInfo)
    if (taoInfo == {} || compareToInfo == {} || !taoInfo || !compareToInfo) {
      return { multiplier: null, taoPriceWithOtherMarketCap: null };
    }

    const { marketCap: taoMarketCap } = taoInfo;
    const { marketCap: otherMarketCap } = compareToInfo;

    const multiplier = otherMarketCap / taoMarketCap;

    const { price: taoPrice } = taoInfo;
    const taoPriceWithOtherMarketCap = taoPrice * multiplier;

    
    return {
      multiplier: multiplier,
      taoPriceWithOtherMarketCap: formatNumber(taoPriceWithOtherMarketCap)
    };
  }
}

// only show 2 decimal places
  // add commas every 3 digits
  const formatNumber = (num) => {
    return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  const shorthandNumber = (num) => {
    if (num < 1000) {
      return num;
    }
    if (num < 1000000) {
      return (num / 1000).toFixed(2) + "K";
    }
    if (num < 1000000000) {
      return (num / 1000000).toFixed(2) + "M";
    }
    if (num < 1000000000000) {
      return (num / 1000000000).toFixed(2) + "B";
    }
    if (num < 1000000000000000) {
      return (num / 1000000000000).toFixed(2) + "T";
    }
    if (num < 1000000000000000000) {
      return (num / 1000000000000000).toFixed(2) + "Q";
    }
    return "too big";
  }

export default App;
